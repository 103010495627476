.div-login-email-pass {
    margin: 5%;
}

.span-lep-get { 
    margin-bottom: 16px;
    display: block;
    font-family: serif;
    font-weight: 700;
    font-size: 36px;
    color: #737171;
}

.span-lep-login {
    margin-left: 61px;
    font-family: sans-serif;
    font-size: 18px;
    color: #000;
}

.span-lep-register {
    cursor: pointer;
    margin-left: 61px;
    font-family: sans-serif;
    font-size: 18px;
    color: blue;
}

.label-lep-userid {
    display: block;
    margin-top: 24px;
    font-size: 16px;
    font-family: sans-serif;
}

.input-lep-userid {
    width: inherit !important;
    padding: 8px 8px !important;
    margin-top: 4px;
    border: 1px solid #ccc !important;
    border-radius: 0px !important;
    margin-bottom: inherit;
    font-size: 16px;
}

.span-lep-userid-error {
    display: block;
    color: red;
    margin-top: 4px;
    font-family: sans-serif;
}

.button-lep-submit-enable {
    cursor: pointer !important;
    background-color: blue !important;
    font-family: sans-serif;
    color: white !important;
    padding: 0px 30px !important;
    display: block;
    margin-top: 24px;
    margin-bottom: 16px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border: none !important;
    border-radius: 16px !important;
    margin-left: 66px;
    font-size: 16px !important;
}

.button-lep-submit-disable {
    cursor: pointer !important;
    background-color: #ddd !important;
    font-family: sans-serif;
    color: #707070 !important;
    padding: 0px 30px !important;
    display: block;
    margin-top: 24px;
    margin-bottom: 16px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border: none !important;
    border-radius: 16px !important;
    margin-left: 66px;
    font-size: 16px !important;
}

.span-lep-forgot-pass {
    cursor: pointer;
    font-family: sans-serif;
    margin-left: 114px;
    color: blue;
    font-size: 14px;
}

.span-lep-or-login {
    font-size: 17px;
    display: block;
    margin-top: 24px;
    margin-bottom: 4px;
    font-family: sans-serif;
    color: #000;
}

.img-gmail {
    cursor: pointer;
}