.main-div-set-pass {
    margin: 5%;
}

.span-expired {
    line-height: 1.6;
    font-size: 24px;
    margin-top: 154px;
    display: table;
    text-align: center;
}

.span-below-email {
    display: block;
    color: #000;
    font-size: 22px;
    font-weight: 600;
    font-family: serif;
}

.span-email {
    margin-top: 16px;
    display: block;
    font-size: 14px;
    font-family: serif;
    color: #000;
}

.input-pass {
    font-size: 16px;
    border: 1px solid #ddd !important;
    margin-bottom: inherit;
    border-radius: inherit !important;
    width: inherit !important;
    margin-top: 16px;
    padding: 12px !important;
    display: block;
}

.label-set-new {
    font-weight: inherit;
    margin-bottom: inherit;
    margin-top: 16px;
    display: block;
}
.span-sp-pass-not {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    color: red;
    font-family: sans-serif;
}

.continue-set-pass-btn-enable {
    background-color: blue !important;
    cursor: pointer !important;
    border-radius: inherit !important;
    background: blue;
    margin-top: 16px;
    color: white !important;
    border: none !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-family: serif;
    margin-right: inherit !important;
}

.continue-set-pass-btn-disable {
    background-color: #ddd!important;
    cursor: default !important;
    border-radius: inherit !important;
    background: #ddd;
    margin-top: 16px;
    color: #000 !important;
    border: none !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-family: serif;
    margin-right: inherit !important;
}