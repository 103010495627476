.Maindiv {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.haveu {
  margin-bottom: 10px;
}

.yn_rad {
  display: flex;
  margin-bottom: 20px;
}

.yn_rad input {
  margin-right: 5px;
}
.yn_rad label {
  margin-right: 4rem;
  margin-top: 0.7rem;
}

.no-ele {
  margin-bottom: 15px;
}

.nero {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.optno {
  margin-top: 20px;
}

.summit {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.summit:hover {
  background-color: #45a049;
}
